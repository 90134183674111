import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, generateHeaders } from './Const';
import '../assets/css/login.css';
import Popup from '../components/popup';

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!email || !password) {
            setError("Veuillez remplir tous les champs");
            setShowPopup(true);
            return;
        }
        const API = API_URL + "/admin/login"

        try {
            
            console.log(API)
            const response = await axios.post(API , { email, password }, { headers: generateHeaders() });
            const { token, data } = response.data;

            // Sauvegarder les données en session
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("email", data.email);
            sessionStorage.setItem("zone", data.zone);

            // Rediriger vers le tableau de bord
            navigate("/");
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
                console.log(error.response.data.error);
            } else {
                setError("Une erreur est survenue lors de la connexion." + error + API);
            }
            setShowPopup(true);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            <div className="login-container">
                <div className="login-image">
                    <img src="https://images.pexels.com/photos/4391478/pexels-photo-4391478.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Image de l'entreprise"/>
                </div>

                <div className="login-form">
                    <h2>Connexion</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                id="email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required 
                                placeholder="Entrez votre email"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input 
                                type="password" 
                                id="password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required 
                                placeholder="Entrez votre mot de passe"
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit">Se connecter</button>
                        </div>
                    </form>
                </div>
            </div>
            {showPopup && <Popup message={error} onClose={closePopup} />}
        </>
    );
}