import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import '../assets/css/clients.css';
import Sidebar from '../components/sidebar';
import { API_URL, generateHeaders } from './Const';

const ClientDetailsPopup = ({ client, onClose }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Détails du client</h2>
                <p><strong>Nom:</strong> {client.name}</p>
                <p><strong>Email:</strong> {client.email}</p>
                <p><strong>Téléphone:</strong> {client.number}</p>
                <p><strong>Adresse:</strong> {client.adresse}</p>
                <p><strong>Date d'inscription:</strong> {new Date(client.createdAt).toLocaleDateString()}</p>
                <button onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
};

const ClientListDashboard = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedClient, setSelectedClient] = useState(null);
    const clientsPerPage = 10;

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(`${API_URL}/admin/getCustomers`, { headers: generateHeaders() });
                setClients(response.data.customers || []);
            } catch (err) {
                console.error('Erreur lors du chargement des clients:', err);
                setClients([]);
            } finally {
                setLoading(false);
            }
        };

        fetchClients();
    }, []);

    const filteredClients = useMemo(() => {
        return clients.filter(client => 
            client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            client.number.includes(searchTerm)
        );
    }, [clients, searchTerm]);

    const pageCount = Math.ceil(filteredClients.length / clientsPerPage);
    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleViewClient = (client) => {
        setSelectedClient(client);
    };

    if (loading) return <div>Chargement...</div>;

    return (
        <>
            <Sidebar/>
            <main className="main-content">
                <header className="main-header">
                    <input 
                        type="search" 
                        placeholder="Rechercher un client" 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="user-profile">
                        <h3>Bienvenue sur Happy {sessionStorage.getItem('email')}</h3>
                    </div>
                </header>

                <section className="client-list">
                    <header>
                        <h1>Liste complète des clients</h1>
                    </header>

                    {currentClients.length === 0 ? (
                        <div className="no-clients-message">
                            Aucun client trouvé.
                        </div>
                    ) : (
                        <table className="client-table">
                            <thead>
                                <tr>
                                    <th>Nom du représentant</th>
                                    <th>Numéro de téléphone</th>
                                    <th>Email</th>
                                    <th>Adresse</th>
                                    <th>Date d'inscription</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentClients.map((client) => (
                                    <tr key={client.id}>
                                        <td>{client.name}</td>
                                        <td>{client.number}</td>
                                        <td>{client.email}</td>
                                        <td>{client.adresse}</td>
                                        <td>{new Date(client.createdAt).toLocaleDateString()}</td>
                                        <td>
                                            <button onClick={() => handleViewClient(client)} className="view-button">👁 Voir</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {filteredClients.length > clientsPerPage && (
                        <div className="pagination">
                            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>‹</button>
                            {[...Array(pageCount)].map((_, i) => (
                                <button 
                                    key={i} 
                                    onClick={() => handlePageChange(i + 1)}
                                    className={currentPage === i + 1 ? 'active' : ''}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>›</button>
                        </div>
                    )}
                </section>
            </main>
            {selectedClient && (
                <ClientDetailsPopup 
                    client={selectedClient} 
                    onClose={() => setSelectedClient(null)} 
                />
            )}
        </>
    );
};

export default ClientListDashboard;