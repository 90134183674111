// src/components/ViewDeliverymanPopup.jsx

import React from 'react';
import PropTypes from 'prop-types';

const ViewDeliverymanPopup = ({ deliveryman, onClose }) => {
    return (
        <div className="popup-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div className="popup-content" style={{
                backgroundColor: '#fff',
                padding: '30px',
                borderRadius: '10px',
                maxWidth: '600px',
                width: '90%',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
                position: 'relative'
            }}>
                <h2 style={{ marginBottom: '25px', textAlign: 'center', fontSize: '24px', color: '#333' }}>Détails du Livreur</h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>Nom complet :</span>
                        <span style={{ color: '#333' }}>{deliveryman.fullname}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>NNI :</span>
                        <span style={{ color: '#333' }}>{deliveryman.nni}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>Email :</span>
                        <span style={{ color: '#333' }}>{deliveryman.email}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>Numéro de téléphone :</span>
                        <span style={{ color: '#333' }}>{deliveryman.number}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>Adresse :</span>
                        <span style={{ color: '#333' }}>{deliveryman.adresse}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>Date de naissance :</span>
                        <span style={{ color: '#333' }}>{new Date(deliveryman.date_of_birth).toLocaleDateString()}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', color: '#555' }}>Statut :</span>
                        <span style={{ color: deliveryman.enable ? '#28a745' : '#dc3545', fontWeight: 'bold' }}>
                            {deliveryman.enable ? 'Activé' : 'Désactivé'}
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '25px' }}>
                    <button 
                        onClick={onClose} 
                        style={{
                            padding: '10px 25px',
                            backgroundColor: '#e65a1e',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            transition: 'background-color 0.3s',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#cc4b17'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#e65a1e'}
                    >
                        Fermer
                    </button>
                </div>
            </div>
        </div>
    );
};

ViewDeliverymanPopup.propTypes = {
    deliveryman: PropTypes.shape({
        fullname: PropTypes.string.isRequired,
        nni: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        adresse: PropTypes.string.isRequired,
        date_of_birth: PropTypes.string.isRequired,
        enable: PropTypes.number
    }).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ViewDeliverymanPopup;
