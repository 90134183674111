// src/components/EditDeliverymanPopup.jsx

import React, { useState } from 'react';
import axios from 'axios';
import { API_URL, generateHeaders } from '../pages/Const';
import PropTypes from 'prop-types';

const EditDeliverymanPopup = ({ deliveryman, onClose, onSuccess, onError }) => {
    const [formData, setFormData] = useState({
        fullname: deliveryman.fullname || '',
        email: deliveryman.email || '',
        number: deliveryman.number || '',
        password: '',
        nni: deliveryman.nni || '',
        adresse: deliveryman.adresse || '',
        date_of_birth: deliveryman.date_of_birth ? deliveryman.date_of_birth.split('T')[0] : '2000-01-01'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Préparer les données à envoyer
        const dataToSend = {
            fullname: formData.fullname,
            email: formData.email,
            number: formData.number,
            nni: formData.nni,
            adresse: formData.adresse,
            date_of_birth: formData.date_of_birth,
        };

        // Ajouter le mot de passe uniquement s'il est saisi
        if (formData.password.trim() !== '') {
            dataToSend.password = formData.password;
        }


        try {
            const response = await axios.put(`${API_URL}/admin/updateDeliveryman/${deliveryman.deliveryman_id}`, dataToSend, {
                headers: {
                    ...generateHeaders(),
                }
            });
            if (response.status === 200) {
                onSuccess(`Modification du livreur ${formData.fullname} avec succès`);
                onClose();
            }
        } catch (error) {
            console.error('Erreur lors de la modification du livreur:', error);
            onError(error.response?.data?.message || "Une erreur est survenue lors de la modification du livreur");
            onClose();
        }
    };

    return (
        <div className="popup-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div className="popup-content" style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                maxWidth: '500px',
                width: '100%'
            }}>
                <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Modifier un livreur</h2>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="fullname" style={{ display: 'block', marginBottom: '5px' }}>Nom complet</label>
                        <input
                            type="text"
                            id="fullname"
                            name="fullname"
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="number" style={{ display: 'block', marginBottom: '5px' }}>Numéro de téléphone</label>
                        <input
                            type="tel"
                            id="number"
                            name="number"
                            value={formData.number}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="password" style={{ display: 'block', marginBottom: '5px' }}>Mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Laissez vide pour ne pas changer"
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="nni" style={{ display: 'block', marginBottom: '5px' }}>NNI</label>
                        <input
                            type="text"
                            id="nni"
                            name="nni"
                            value={formData.nni}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="adresse" style={{ display: 'block', marginBottom: '5px' }}>Adresse</label>
                        <input
                            type="text"
                            id="adresse"
                            name="adresse"
                            value={formData.adresse}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="date_of_birth" style={{ display: 'block', marginBottom: '5px' }}>Date de naissance</label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <button type="submit" style={{
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}>Modifier</button>
                        <button type="button" onClick={onClose} style={{
                            padding: '10px 20px',
                            backgroundColor: '#f44336',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

EditDeliverymanPopup.propTypes = {
    deliveryman: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default EditDeliverymanPopup;
