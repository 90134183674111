import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaHome, FaClipboardList, FaUsers, FaTruck, FaSignOutAlt } from 'react-icons/fa';

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path) => {
        return location.pathname === path ? "active" : "";
    };

    const handleLogout = () => {
        // Effacer toutes les données de session
        sessionStorage.clear();

        // Rediriger vers la page de connexion
        navigate('/');
    };

    return (
        <nav className="sidebar">
            <div className="logo">
                <h2>Happy Board</h2>
            </div>
            <ul className="menu">
                <li>
                    <Link to="/" className={isActive("/")}>
                        <FaHome className="menu-icon" />
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to="/commandes" className={isActive("/commandes")}>
                        <FaClipboardList className="menu-icon" />
                        <span>Commandes</span>
                    </Link>
                </li>
                <li>
                    <Link to="/clients" className={isActive("/clients")}>
                        <FaUsers className="menu-icon" />
                        <span>Clients</span>
                    </Link>
                </li>
                <li>
                    <Link to="/livreurs" className={isActive("/livreurs")}>
                        <FaTruck className="menu-icon" />
                        <span>Livreurs</span>
                    </Link>
                </li>
            </ul>
            <div className="update-banner">
                <a href="#">Mettez à jour votre Compte et obtenez un bon d'achat</a>
            </div>
            <div className="user-info">
                <button onClick={handleLogout} className="logout-button">
                    <FaSignOutAlt className="menu-icon" />
                    <span>Déconnexion</span>
                </button>
            </div>
        </nav>
    );
};

export default Sidebar;