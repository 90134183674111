import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, generateHeaders } from '../pages/Const';


const useDeliveryCountAlert = () => {
    const [deliveryCount, setDeliveryCount] = useState(0);
    const [hasNewDeliveries, setHasNewDeliveries] = useState(false);
    const [newDeliveries, setNewDeliveries] = useState(0);

    useEffect(() => {
        const fetchDeliveries = async () => {
            try {
                const response = await axios.get(`${API_URL}/admin/getAdminDeliveries`, { headers: generateHeaders() });
                const newCount = response.data.deliveries.length;
                
                if (newCount > deliveryCount) {
                    setHasNewDeliveries(true);
                    setNewDeliveries(newCount - deliveryCount);
                }
                
                setDeliveryCount(newCount);
            } catch (error) {
                console.error('Erreur lors de la récupération des livraisons:', error);
            }
        };

        fetchDeliveries(); // Appel initial

        const interval = setInterval(fetchDeliveries, 60000); // Vérification toutes les minutes

        return () => clearInterval(interval); // Nettoyage à la désinstallation du composant
    }, [deliveryCount]);

    const resetAlert = () => {
        setHasNewDeliveries(false);
        setNewDeliveries(0);
    };

    return { deliveryCount, hasNewDeliveries, newDeliveries, resetAlert };
};

export default useDeliveryCountAlert;