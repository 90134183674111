export const API_URL = 'https://bethel.lycorisblue.com';
export const MAX_ITEMS = 100;
export const DEFAULT_LANGUAGE = 'fr';

// Assurez-vous d'importer ou de définir votre HMS_KEY quelque part dans votre application
const HMS_KEY = 'v7bwADHezaTA889R8x4sUE992BM79vim'; // Remplacez ceci par votre vraie clé

export const generateHeaders = () => {
  // Initialiser les headers avec le HMS_KEY
  const headers = {
    'Content-Type': 'application/json',
    'hmskey': HMS_KEY
  };

  // Vérifier si un token existe en session
  const token = sessionStorage.getItem('token');

  // Si le token existe, l'ajouter aux headers
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};