import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Dashboard from './pages/Dashboard';
import ClientListDashboard from './pages/Clients';
import OrderListDashboard from './pages/Commandes';
import Login from './pages/Login';
import DeliveryListDashboard from './pages/Livreurs';
import LoadingAnimation from './components/loading';
import ProtectedRoute from './components/ProtectedRoute'; // Importez le nouveau composant

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <div className="App">
      <AnimatePresence>
        {isLoading && <LoadingAnimation />}
      </AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } />
        <Route path="/clients" element={
          <ProtectedRoute>
            <ClientListDashboard />
          </ProtectedRoute>
        } />
        <Route path="/commandes" element={
          <ProtectedRoute>
            <OrderListDashboard />
          </ProtectedRoute>
        } />
        <Route path="/livreurs" element={
          <ProtectedRoute>
            <DeliveryListDashboard />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  );
}

export default App;